//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SubCategList from "./listino_subcateg_mobile";
import listino_main_mixin from "../mixins/listino_main_mixin.js";
export default {
  props: {
    lista: Array,
  },
  components: {
    SubCategList,
  },
  mixins: [listino_main_mixin],
};
