//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DialogDettaglio from './listino_dettaglio_prodotto_mobile'
import list_prod_mixin from "../mixins/listino_prodotto_element_mixin"
export default {
    components: {
        DialogDettaglio,
    },
    mixins:[list_prod_mixin]
    
}
